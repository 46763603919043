.body-grimoire {
    background-color: #d0d0d0;
    background-image: url("../../img/background-img.webp");
    background-repeat: repeat;
    background-position: 0 0;
}

.body-sheet {
    background-color: #fffef0;
    background-image: url("../../img/token/character-token-noise.webp");
}
