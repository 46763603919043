.character,
.reminder {
    border-radius: 50%;
    display: grid;
    width: 100%;
    aspect-ratio: 1 / 1;
}

.character {
    --no-leaf: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
    --top-leaf: var(--no-leaf);
    --left-leaf: var(--no-leaf);
    --right-leaf: var(--no-leaf);
    --setup-leaf: var(--no-leaf);
    --reminder-offset: 2.5%;
    --shadow-offset: 0.1em;
    --shadow-rotation: 1;
    --shadow-amount: calc(var(--shadow-offset) * var(--shadow-rotation));

    background-color: #fffef0;
    background-image:
        var(--left-leaf),
        var(--top-leaf),
        var(--right-leaf),
        var(--setup-leaf),
        url("../../img/token/clockface-2.webp"),
        url("../../img/token/character-token-noise.webp");
    background-position: 50% 50%;
    background-repeat:
        no-repeat,
        no-repeat,
        no-repeat,
        no-repeat,
        no-repeat,
        repeat;
    background-size:
        contain,
        contain,
        contain,
        contain,
        60%,
        auto;
    box-shadow:
        inset 0.1em 0.1em 0.2em rgb(255 255 255 / 0.2),
        inset 0 0 0.7em #8a4d0f,
        var(--shadow-amount) var(--shadow-amount) 0.2em rgb(0 0 0 / 0.8);

    &.is-upside-down {
        --shadow-rotation: -1;
        transform: rotate(180deg);
    }

    &.is-dead {
        filter: grayscale(1) brightness(0.8);
    }
}

.character--top-1 { --top-leaf: url("../../img/token/top-1.webp"); }
.character--top-2 { --top-leaf: url("../../img/token/top-2.webp"); }
.character--top-3 { --top-leaf: url("../../img/token/top-3.webp"); }
.character--top-4 { --top-leaf: url("../../img/token/top-4.webp"); }
.character--top-5 { --top-leaf: url("../../img/token/top-5.webp"); }
.character--top-6 { --top-leaf: url("../../img/token/top-6.webp"); }
.character--left-1 { --left-leaf: url("../../img/token/left-1.webp"); }
.character--right-1 { --right-leaf: url("../../img/token/right-1.webp"); }
.character--right-2 { --right-leaf: url("../../img/token/right-2.webp"); }
.character--setup { --setup-leaf: url("../../img/token/setup.webp"); }

.character[data-first-night]::before,
.character[data-other-night]::after {
    border-radius: 50%;
    color: #fff;
    font-weight: bold;
    width: 25%;
    aspect-ratio: 1 / 1;
    display: var(--night-order-display, flex);
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    font-size: clamp(0.75em, 3.5vw - 0.5em, 2em);
    line-height: 1;
    grid-area: 1 / -1;
    z-index: 2;
    margin-top: var(--reminder-offset);
}

.character.is-upside-down[data-first-night]::before,
.character.is-upside-down[data-other-night]::after {
    transform: rotate(180deg);
    margin-top: 0;
    margin-bottom: var(--reminder-offset);
    align-self: flex-end;
}

.character[data-first-night]::before {
    content: attr(data-first-night);
    background-image: linear-gradient(to bottom, #006, #00c);
    margin-left: var(--reminder-offset);
    margin-right: auto;
}

.character.is-upside-down[data-first-night]::before {
    margin-left: auto;
    margin-right: var(--reminder-offset);
}

.character[data-other-night]::after {
    content: attr(data-other-night);
    background-image: linear-gradient(to bottom, #600, #c00);
    margin-left: auto;
    margin-right: var(--reminder-offset);
}

.character.is-upside-down[data-other-night]::after {
    margin-left: var(--reminder-offset);
    margin-right: auto;
}

.reminder {
    background-color: #101128;
    box-shadow:
        inset 0.1em 0.1em 0.2em rgb(255 255 255 / 0.1),
        inset 0 0 1em #111324,
        inset 0 0 0.5em #111324,
        0.1em 0.1em 0.2em rgb(0 0 0 / 0.8);
}

.character__image,
.character__text,
.character__shroud,
.reminder__image,
.reminder__text {
    grid-area: 1 / -1;
    z-index: 1;
}

.character__image,
.reminder__image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.character__shroud {
    --background-width: calc(75% * (233 / 419));
    z-index: 2;
    background-image:
        url("../../img/token/shroud.webp"),
        linear-gradient(to bottom, #555 57%, transparent 57%);
    background-size:
        auto 75%,
        var(--background-width) auto;
    background-repeat: no-repeat;
    background-position: 50% 0;
    filter: drop-shadow(0.1em 0.1em 0.2em rgba(0 0 0 / 0.8));
    display: none;

    @at-root {
        .is-upside-down > #{&} {
            transform: rotate(180deg);
        }

        .is-dead > #{&} {
            display: block;
        }
    }
}

.character__ghost-vote {
    color: #fff;
    width: 30%;
    transform: translateY(100%);

    @at-root {
        .is-voteless & {
            display: none;
        }
    }
}

.character__icon {
    width: 90%;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.character__name {
    font-family: var(--serif-font);
    letter-spacing: 0.1em;
}

.character__player-name {
    display: block;
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: #fffef0;
    background-image: url("../../img/token/character-token-noise.webp");
    box-shadow:
        inset 0.1em 0.1em 0.2em rgb(255 255 255 / 0.2),
        inset 0 0 0.7em #8a4d0f,
        var(--shadow-amount) var(--shadow-amount) 0.2em rgb(0 0 0 / 0.8);
    font-size: 0.8em;

    &:empty {
        display: none;
    }

    @at-root {
        .is-upside-down > & {
            // Just setting --shadow-rotation doesn't work.
            --shadow-amount: var(--shadow-offset);
            top: auto;
            bottom: 100%;
            transform: rotate(180deg);
        }
    }
}

.character__unknown {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3em;

    @at-root {
        [disabled] #{&} {
            display: none;
        }
    }
}

.reminder__text {
    color: #fff;
    font-size: 1.2em;
    letter-spacing: 0.05em;
}

.reminder__icon {
    width: 70%;
}
