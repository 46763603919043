.tick-select__highlight {
    display: block;
    position: relative;

    @at-root {
        .tick-select__checkbox:checked + & {
            &::before {
                content: "✓";
                position: absolute;
                left: 50%;
                top: 50%;
                z-index: 1;
                transform: translate(-50%, -50%);
                font-size: 4em;
                color: #fff;
                text-shadow: 0 0 0.25em #000;
            }

            > .tick-select__icon {
                transform: scale(0.9);
                transform-origin: center;
            }
        }
    }

}
